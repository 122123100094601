<template>
  <router-link :to="`${$locale.base}${link}`"
               class="excursion-card grid-sm-4 grid-md-4 grid-lg-4">
    <div class="excursion-card__wrapper">
      <div class="excursion-card__image-holder">
        <img class="excursion-card__image"
             :src="image"
             alt/>
        <Favorite class="excursion-card__favorite"
                  :params="{id, entity, favorited}"/>
        <span class="excursion-card__cost">{{cost}}</span>
        <div v-if="agencyImages || guideImages"
             class="excursion-card__agency">
          <img class="excursion-card__agency-image"
               :src="agencyImage || guideImage"
               alt/>
        </div>
        <Row v-if="params && params.tags && params.tags.length > 0"
             class="excursion-card__badges -m-5"
             wrap>
          <Badge v-for="badge in params.tags"
                 :key="`badge-excursion-card${badge.id}`"
                 :params="{text: badge.title, colors: {
                       text: '#ffffff', background: badge.color}}"/>
        </Row>
      </div>
      <div class="excursion-card__meta">
        <Row>
          <div class="excursion-card__title-holder">
            <h5 class="excursion-card__title">{{params.title}}</h5>
          </div>
        </Row>
        <Row class="excursion-card__info -m-h-15">
          <Column class="excursion-card__info-column"
                  v-if="params.duration">
            <Icon class="excursion-card__info-icon"
                  xlink="duration"
                  viewport="0 0 20 20"/>
            <span class="excursion-card__info-value">{{params.duration}}</span>
          </Column>
          <Column class="excursion-card__info-column"
                  v-if="likeTrip && params.distance">
            <Icon class="excursion-card__info-icon"
                  xlink="route"
                  viewport="0 0 20 20"/>
            <span class="excursion-card__info-value">{{params.distance}}</span>
          </Column>
          <Column class="excursion-card__info-column"
                  v-if="!likeTrip && params.subtype && params.subtype.name"
                  v-html="getSubTypes()">
          </Column>
          <Column class="excursion-card__info-column">
            <Rating :params="{id: params.id,
                              component: 'Column',
                              align: 'start',
                              type: 'trips',
                              rating: params.rating,
                              currentUserRating: params.currentUserRating}"/>
          </Column>
        </Row>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'ExcursionCard',
  props: {
    params: {},
    likeTrip: {
      default: () => false,
    },
  },
  methods: {
    getSubTypes() {
      if (!this.$tripSubTypesSVG.length || this.params.subtype_id === null) return '';
      const div = document.createElement('div');
      div.innerHTML = this.$tripSubTypesSVG.find((svg) => svg.id === this.params.subtype_id).img;
      const svg = div.getElementsByTagName('svg')[0];
      svg.classList.add('icon');
      svg.classList.add('excursion-card__info-icon');
      svg.style.width = '20px';
      svg.style.height = '20px';
      div.getElementsByTagName('g')
        .forEach((el) => {
          const element = el;
          element.style.fill = 'rgba(0, 0, 0, .5)';
        });
      return `${div.innerHTML}<span class="excursion-card__info-value">${this.params.subtype.name}</span>`;
    },
  },
  computed: {
    id() {
      return this?.params?.id;
    },
    entity() {
      return this?.params?.entity;
    },
    favorited() {
      return this?.params?.favorited;
    },
    cost() {
      return this?.params?.cost;
    },
    image() {
      return this?.params?.images[0];
    },
    link() {
      return this?.params?.link;
    },
    agencyImages() {
      return this?.params?.agency?.images;
    },
    guideImages() {
      return this?.params?.guide?.images;
    },
    agencyImage() {
      return this?.params?.agency?.images[0];
    },
    guideImage() {
      return this?.params?.guide?.images[0];
    },
  },
};
</script>
