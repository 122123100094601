var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"excursion-card grid-sm-4 grid-md-4 grid-lg-4",attrs:{"to":`${_vm.$locale.base}${_vm.link}`}},[_c('div',{staticClass:"excursion-card__wrapper"},[_c('div',{staticClass:"excursion-card__image-holder"},[_c('img',{staticClass:"excursion-card__image",attrs:{"src":_vm.image,"alt":""}}),_c('Favorite',{staticClass:"excursion-card__favorite",attrs:{"params":{id: _vm.id, entity: _vm.entity, favorited: _vm.favorited}}}),_c('span',{staticClass:"excursion-card__cost"},[_vm._v(_vm._s(_vm.cost))]),(_vm.agencyImages || _vm.guideImages)?_c('div',{staticClass:"excursion-card__agency"},[_c('img',{staticClass:"excursion-card__agency-image",attrs:{"src":_vm.agencyImage || _vm.guideImage,"alt":""}})]):_vm._e(),(_vm.params && _vm.params.tags && _vm.params.tags.length > 0)?_c('Row',{staticClass:"excursion-card__badges -m-5",attrs:{"wrap":""}},_vm._l((_vm.params.tags),function(badge){return _c('Badge',{key:`badge-excursion-card${badge.id}`,attrs:{"params":{text: badge.title, colors: {
                     text: '#ffffff', background: badge.color}}}})}),1):_vm._e()],1),_c('div',{staticClass:"excursion-card__meta"},[_c('Row',[_c('div',{staticClass:"excursion-card__title-holder"},[_c('h5',{staticClass:"excursion-card__title"},[_vm._v(_vm._s(_vm.params.title))])])]),_c('Row',{staticClass:"excursion-card__info -m-h-15"},[(_vm.params.duration)?_c('Column',{staticClass:"excursion-card__info-column"},[_c('Icon',{staticClass:"excursion-card__info-icon",attrs:{"xlink":"duration","viewport":"0 0 20 20"}}),_c('span',{staticClass:"excursion-card__info-value"},[_vm._v(_vm._s(_vm.params.duration))])],1):_vm._e(),(_vm.likeTrip && _vm.params.distance)?_c('Column',{staticClass:"excursion-card__info-column"},[_c('Icon',{staticClass:"excursion-card__info-icon",attrs:{"xlink":"route","viewport":"0 0 20 20"}}),_c('span',{staticClass:"excursion-card__info-value"},[_vm._v(_vm._s(_vm.params.distance))])],1):_vm._e(),(!_vm.likeTrip && _vm.params.subtype && _vm.params.subtype.name)?_c('Column',{staticClass:"excursion-card__info-column",domProps:{"innerHTML":_vm._s(_vm.getSubTypes())}}):_vm._e(),_c('Column',{staticClass:"excursion-card__info-column"},[_c('Rating',{attrs:{"params":{id: _vm.params.id,
                            component: 'Column',
                            align: 'start',
                            type: 'trips',
                            rating: _vm.params.rating,
                            currentUserRating: _vm.params.currentUserRating}}})],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }